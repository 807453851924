/**
 * ---------------------------------------------------------------
 * GLOBAL VARIABLES
 * ---------------------------------------------------------------
 */

    var width       = getWidth();
    var menuActive  = false;
    var snapper;

    // Pre saved HTML
    var mainMenuHtml    = $('#top-nav').html();


/**
 *---------------------------------------------------------------
 * DOCUMENT READY FUNCTIONS
 *---------------------------------------------------------------
*/
    $(document).ready(function() {

        bannerConfig();
        slideshowConfig();
        responsiveNav();
        openNav();

    });


/**
 * ---------------------------------------------------------------
 * RESIZE FUNCTIONS
 * ---------------------------------------------------------------
 */


    $(window).resize(function() {

        if (width === getWidth()) {
            return;
        }

        width = getWidth();

        responsiveNav();

    });


/**
 *---------------------------------------------------------------
 * BANNER CONFIG
 *---------------------------------------------------------------
*/

    function bannerConfig()
    {
        if (!$(".banner").length) {
            return false;
        }

        var time = $('.banner').data('time');
        var autoplay = true;

        if (time == '' || time === undefined) {
            time = 0;
            autoplay = false;
        }

        $(".banner").owlCarousel({
            loop: true,
            nav: false,
            dots: false,
            items: 1,
            autoplay: autoplay,
            autoplayTimeout: time,
            autoplayHoverPause: true
        });
    }

/**
 *---------------------------------------------------------------
 * GALLERY SLIDESHOW CONFIG
 *---------------------------------------------------------------
*/

    function slideshowConfig() {
         if (!$(".owl-carousel").length) {
            return false;
         }

        $(".owl-carousel").owlCarousel({
            loop:true,
            margin:10,
            nav:false,
            dots: false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:5
                }
            }
        });

    }


/**
 * ---------------------------------------------------------------
 * RESPONSIVE NAV
 * ---------------------------------------------------------------
 */

    function responsiveNav()
    {

        if (getWidth() >= 768) {
            removeSlideoutMenu();
        } else {
            slideoutMenu();
        }
    }


/**
 * ---------------------------------------------------------------
 * ON CLICK FUNCTION FOR MOBILE NAV
 * ---------------------------------------------------------------
 */

    function openNav()
    {
        $('.btn-menu').click(function() {

            if (getWidth() >= 768) {
                return false;
            }

            if (!$('body').hasClass('snapjs-right')) {

                snapper.open('right');

            } else {

                if ($('body').hasClass('ie-snap')) {
                    $('body').removeClass('snapjs-right');
                }

            }

        });
    }


/**
 * ---------------------------------------------------------------
 * ADD SLIDEOUT MENU
 * ---------------------------------------------------------------
 */

    function slideoutMenu()
    {

        if (menuActive === false) {

            $('#top-nav ul').remove();

            $('body').append('<div class="snap-drawers"><div class="snap-drawer snap-drawer-right" id="menu-right"></div></div>');

            $('#menu-right').html(mainMenuHtml);

            snapper = new Snap({
                element: document.getElementById('container'),
                hyperextensible: false,
                disable: 'left'
            });

            // Add class to IE HTML tag
            var browser = navigator.userAgent;

            if (browser.indexOf('Trident') != -1) {
                $('body').addClass('ie-snap');
            }

            $('#menu-right > ul > li > a').click(function(event) {

                subMenu = $(this).parent().find('ul');

                if (subMenu.length && !subMenu.parent().hasClass('open')) {

                    subMenu.slideDown();
                    event.preventDefault();
                    subMenu.parent().addClass('open');
                    return false;
                }

            });

            menuActive = true;

        }

    }



/**
 * ---------------------------------------------------------------
 * REMOVE SLIDEOUT MENU
 * ---------------------------------------------------------------
 */

    function removeSlideoutMenu()
    {
        if (menuActive === true) {

            // Remove unwanted styles
            $('body').removeClass('snapjs-right');
            $('#container').removeAttr('style');
            $('.snap-drawers').remove();
            $('#top-nav').html(mainMenuHtml);

            snapper.disable();
            menuActive = false;

        }
    }


/**
 *---------------------------------------------------------------
 * GET CURRENT WIDTH OF PAGE
 *---------------------------------------------------------------
*/

    function getWidth()
    {
        // Use Header container due to width changing with scroll bars
        return $('header .container').outerWidth();
    }


/**
 *---------------------------------------------------------------
 * OVERRIDE SCROLL DOWN
 *---------------------------------------------------------------
*/

    function overrideScroll()
    {
        // Stop the animation if the user scrolls. Defaults on .stop() should be fine
        $('html, body').bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e){
            if (e.which > 0 || e.type === "mousedown" || e.type === "mousewheel"){
                $('html, body').stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup');
            }
        });
    }
